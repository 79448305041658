<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Settings List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['settings/create'])"
                       :to="'/settings/create'"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'settings/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['settings/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/settings/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i>
                </router-link>

<!--                <a v-if="$store.getters['auth/checkAccess']('settings/delete')" v-tooltip.top-center="'Delete'"-->
<!--                   class="btn-danger btn-simple btn-link"-->
<!--                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i>-->
<!--                </a>-->

              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this settings item?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";


export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Description', value: 'description', minWidth: '200', align: 'center'},
        {label: 'Ident', value: 'ident', minWidth: '200', align: 'center'},
        {label: 'Value', value: 'value', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      componentKey: 0,
    }

  },
  created(){
    this.responsiveViewPort();
  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("settings/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Settings Item deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
            this.tableColumns = [
                {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
                {label: 'Ident', value: 'ident', minWidth: '200', align: 'center'},
                {label: 'Value', value: 'value', minWidth: '200', align: 'center'},
            ];
        }else{
            this.fixedColumn = 'right';
            this.tableColumns = [
                {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
                {label: 'Description', value: 'description', minWidth: '200', align: 'center'},
                {label: 'Ident', value: 'ident', minWidth: '200', align: 'center'},
                {label: 'Value', value: 'value', minWidth: '200', align: 'center'},
            ];
        }
    }
  },
}
</script>
<style>
</style>
